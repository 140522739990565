.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2vmin;
  color: black;
}

.App-link {
  color: black;
}

button:active {
  outline: none;
}

textarea:active,
textarea:focus {
  outline: none;
}

a,
a:visited {
  color: black;
  text-decoration: none;
}
